
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

interface Contact {
  id: number|null,
  first_name: string,
  last_name: string,
  email: string|null,
  phone: string|null
}

export default Vue.extend({
    name: "ContactsAutocompleteMixin",
    props: {
      position: {
        type: Number,
        required: true
      }
    },
    data: () => ({
        contacts: [] as Contact[],
        searchContacts: "" as string,
    }),
    watch: {
      searchContacts(val: string) {
        this.getContacts(val)
      },
      position() {
        this.getContacts()
      }
    },

    mounted() {
      this.getContacts()
    },
    methods: {
      getContacts(search: string|null = null) {
        if (this.position) {
          let url = `/v1/contacts?client_id=${this.position}`
          if (search) {
            url += `&searches=${encodeURIComponent(search)}`
          }
          axios
          .get(url)
          .then((response: AxiosResponse) => {
            this.contacts = response.data
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
        }
      }
    }
})
