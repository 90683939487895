
import Vue, { VueConstructor } from "vue"
import axios, { AxiosResponse, AxiosError } from "axios"
import { EventBus } from "@/plugins/eventBus"
import dateFormatMixin from "@/mixins/DateFormatMixin.vue"

interface Status {
    "weight": number,
    "id": number,
    "title": string
}

interface Worker {
    "id": number,
    "last_name": string,
    "first_name": string,
    "profile_picture": string,
    "title": string,
    "order_workers": OrderWorker[]
}

type OrderWorkerValue = string | number | null | boolean | Period
type RequestBodyValue = OrderWorkerValue | number[]

interface OrderWorker {
    "period_id": number,
    "created_at": string,
    "updated_at": string|null,
    "status": number,
    "arrived_at": string|null,
    "contract_done": boolean,
    "period": Period,
    "reason": string|null,
    "hour_rate": number|null,
    "coefficient": number|null,
    "proof": string|null,
    [key: string]: OrderWorkerValue
}

interface Period {
    "id": number,
    "started_at": string,
    "finished_at": string,
    "formated_start_date": string,
    "formated_end_date": string,
    "number": number,
    "coefficient": number,
    "proof": string,
    "reason": string,
    "hour_rate": number
}

type UpdateValueType = string | null | boolean

export default (Vue as VueConstructor<Vue & InstanceType<typeof dateFormatMixin>>).extend({
    name: "ValidatedWorkerList",
    mixins: [
        dateFormatMixin
    ],
    props: {
        id: {
            type: Number,
            default: null
        },
        reload: {
            type: Number,
            default: null
        },
        fromClosing: {
            type: Boolean,
            default: false
        },
        fromContract: {
            type: Boolean,
            default: false
        },
        filterPeriodIds: {
            type: Array,
            default: () => [] as number[]
        },
        isClosed: {
            type: Boolean,
            default: false
        },
        coefficient: {
            type: Number,
            default: null
        },
        hourRate: {
            type: Number,
            default: null
        },
        reason: {
            type: String,
            default: null
        },
        proof: {
            type: String,
            default: null
        }
    },
    data: function () {
        return {
            workers: [] as Worker[],
            periods: [] as Period[],
            orderData: {
                reason: null,
                proof: null,
                hour_rate: null,
                coefficient: null
            } as OrderWorker,
            validatedStatus: [] as Status[],
            contract_type: null as string|null,
            workerContractsDone: [] as boolean[],
            reasonItems: [
                {value: "replacing_absent_employee", text: this.$vuetify.lang.t('$vuetify.reasons.replacing_absent_employee')},
                {value: "replacement_before_delete_post", text: this.$vuetify.lang.t('$vuetify.reasons.replacement_before_delete_post')},
                {value: "waiting_hire_C.D.I.", text: this.$vuetify.lang.t('$vuetify.reasons.waiting_hire_CDI')},
                {value: "complementary_professional_training", text: this.$vuetify.lang.t('$vuetify.reasons.complementary_professional_training')},
                {value: "facilitating_recruitment_for_difficulties_unemployment", text: this.$vuetify.lang.t('$vuetify.reasons.facilitating_recruitment_for_difficulties_unemployment')},
                {value: "not_employed_replacement", text: this.$vuetify.lang.t('$vuetify.reasons.not_employed_replacement')},
                {value: "temporary_increase_activity", text: this.$vuetify.lang.t('$vuetify.reasons.temporary_increase_activity')},
                {value: "occasional,_specific_and_non-lasting_stain", text: this.$vuetify.lang.t('$vuetify.reasons.occasional,_specific_and_non-lasting_task')},
                {value: "exceptional_export_order", text: this.$vuetify.lang.t('$vuetify.reasons.exceptional_export_order')},
                {value: "urgent_work_required_for_safety_reasons", text: this.$vuetify.lang.t('$vuetify.reasons.urgent_work_required_for_safety_reasons')},
                {value: "facilitating_the_hiring_of_the_labour_code", text: this.$vuetify.lang.t('$vuetify.reasons.facilitating_the_hiring_of_the_labour_code')},
                {value: "temporary_vacancy_of_the_Labour_Code", text: this.$vuetify.lang.t('$vuetify.reasons.temporary_vacancy_of_the_Labour_Code')},
                {value: "constant_use", text: this.$vuetify.lang.t('$vuetify.reasons.constant_use')},
                {value: "seasonal_employment", text: this.$vuetify.lang.t('$vuetify.reasons.seasonal_employment')},
                {value: "training_course", text: this.$vuetify.lang.t('$vuetify.reasons.training_course')},
                {value: "CIPI_training_contracts", text: this.$vuetify.lang.t('$vuetify.reasons.CIPI_training_contracts')},
                {value: "CDPI_training_contracts", text: this.$vuetify.lang.t('$vuetify.reasons.CDPI_training_contracts')},
                {value: "professionalisation_contract", text: this.$vuetify.lang.t('$vuetify.reasons.professionalisation_contract')},
                {value: "training_plan", text: this.$vuetify.lang.t('$vuetify.reasons.training_plan')},
                {value: "qualification_contract", text: this.$vuetify.lang.t('$vuetify.reasons.qualification_contract')}
            ]
        }
    },
    watch: {
        reload: async function() {
            await this.getStatus()
        },
        coefficient: async function() {
            await this.getStatus()
        },
        hourRate: async function() {
            await this.getStatus()
        },
        reason: async function() {
            await this.getStatus()
        },
        proof: async function() {
            await this.getStatus()
        }
    },
    async mounted() {
        await this.getStatus()
    },
    methods: {
        /**
         * Change the data of the worker or the order_worker
         * @param workerId The id of the worker
         * @param type The type of the data to change
         * @param value The value of the data to change
         * @param index The index of the order_worker to change
         */
        changeData(workerId: number, type: string, value: OrderWorkerValue, index: number|null = null) {
            const processedValue = this.processValue(type, value)
            const worker = this.findWorker(workerId)
            
            if (!worker) {
                EventBus.$emit('snackbar', {message: this.$vuetify.lang.t('$vuetify.worker_not_found')})
                return
            }
            if (index !== null) {
                this.updateSingleOrderWorker(worker, index, type, processedValue)
            } else {
                this.updateAllOrderWorkers(worker, type, processedValue)
            }
        },

        /**
         * Process the value based on its type
         */
        processValue(type: string, value: OrderWorkerValue): OrderWorkerValue {
            if (['hour_rate', 'coefficient'].includes(type)) {
                const numValue = Number(value)
                return numValue === 0 ? null : numValue
            }
            if (typeof value === 'string' && value === '') {
                return null
            }
            return value
        },

        /**
         * Find a worker by its ID
         */
        findWorker(workerId: number): Worker | undefined {
            return this.workers.find(worker => worker.id === workerId)
        },

        /**
         * Update a single order worker
         */
        updateSingleOrderWorker(worker: Worker, index: number, type: string, value: OrderWorkerValue) {
            worker.order_workers[index][type] = value
            this.requestToUpdateOrderWorker({
                [worker.id]: {
                    status: worker.order_workers[index].status,
                    periods: [worker.order_workers[index].period_id],
                    [type]: value
                }
            })
        },

        /**
         * Update all order workers for a given worker
         */
        updateAllOrderWorkers(worker: Worker, type: string, value: OrderWorkerValue) {
            const periodIds = worker.order_workers.map(ow => {
                ow[type] = value
                return ow.period_id
            })

            this.requestToUpdateOrderWorker({
                [worker.id]: {
                    status: worker.order_workers[0].status,
                    periods: periodIds,
                    [type]: value
                }
            })
        },
        /**
         * Update the contract of the worker
         * @param workerId The id of the worker
         * @param index The index of the worker in the workers array
         */
        updateContract(workerId: number, index: number) {
            let error = true
            for (const worker of this.workers) {
                if (worker.id === workerId) {
                    worker.order_workers.forEach((ow: OrderWorker) => {
                        this.updateOrderWorkerStatus(workerId, ow.period_id, ow.status, "contract_done", !this.workerContractsDone[index] , null)
                        error = false
                    })
                    break
                }
            }
            if (error) {
                EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.worker_not_found')})
            }
        },
        /**
         * Get the statuses
         */
        async getStatus(): Promise<void> {
            // We clear the datas before fetching the new ones
            this.workers = []
            this.validatedStatus = []
            
            // Get the order's template status with weight = 1
            let orderError = false
            await axios.get('/v1/orders/' + this.id)
            .then((response: AxiosResponse) => {
                    this.orderData = response.data
                    this.contract_type = response.data.template.contract_type;
                    (response.data.template.status as Array<Status>).forEach(status  => {
                        if (status.weight === 1) {
                            this.validatedStatus.push(status)
                        }
                    })
                }
            )
            .catch((e: AxiosError) => {
                orderError = true
                EventBus.$emit('snackbar',{axiosError: e})
            })
            // If we didn't get the statuses, we can't get the corresponding workers
            if (orderError) {
                return
            }

            // For each status with weight = 1, we get the corresponding workers
            for (const status of this.validatedStatus) {
                await this.getWorkers(status)
            }

            // For each of the fetched workers, we get their order_workers periods
            await this.getPeriods()

            if (this.fromClosing || this.fromContract) {
                this.$emit('orderWorkerIds', this.workers)
            }

            this.showPeriods()
        },
        /**
         * Show the periods
         */
        showPeriods(): void {
            let testContract = []
            for (const worker of this.workers) {
                // We set a count for the period's number
                for (const orderWorker of worker.order_workers) {
                    testContract.push(Boolean(orderWorker.contract_done).valueOf())
                    let period = this.periods.find(p => p.id === orderWorker.period_id) as Period
                    if (period && period.id) {
                        period.formated_start_date = this.localizeDate(period.started_at, true, true).slice(0, -3)
                        period.formated_end_date = this.localizeDate(period.finished_at, true, true).slice(0, -3)
                        // We use Vue.set for deep reactivity
                        this.setterPeriod(period).forEach((p: Period) => {
                            Vue.set(orderWorker,"period",p)
                        })
                    }
                }
                this.workerContractsDone[this.workers.indexOf(worker)] = !testContract.includes(false)
            }
        },
        /**
         * Setter for the periods
         * @param period The period to set
         * @returns The periods to set
         */
        setterPeriod(period: Period): Period[] {
            let periodsToSet: Period[] = []
            if (this.fromClosing ? new Date(period.started_at) < new Date() : true) {
                if (this.filterPeriodIds.length > 0) {
                    this.filterPeriodIds.forEach((periodId) => {
                        if (periodId === period.id) {
                            periodsToSet.push(period)
                        }
                    });
                } else {
                    periodsToSet.push(period)
                }
            }
            return periodsToSet
        },
        /**
         * Get the workers
         * @param status The status of the workers
         * @param page The page of the workers
         */
        async getWorkers(status: Status, page = 1): Promise<void> {
            await axios.get(`/v1/workers?page=${page}&fields=first_name,last_name,profile_picture&order=true:${this.id}:${status.id}`)
            .then((response: AxiosResponse) => {
                let result = response.data.map((worker: Worker) => {
                    return {...worker, title: status.title}
                })
                let workersId: number[] = [];
                this.workers.forEach((worker: Worker) => {
                    workersId.push(worker.id)
                });
                this.workers = this.workers.concat(result.map((worker: Worker)=> {
                    if (!workersId.includes(worker.id)) {
                        return worker
                    }
                }))
                this.workers = this.workers.filter((w: Worker)=> w)
                let nb = /-(\d+)\//.exec(response.headers["content-range"])
                let total = /\/(\d+)/.exec(response.headers["content-range"])

                if (nb && total && (Number(nb[1]) < Number(total[1]))) {
                    this.getWorkers(status, page + 1)
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        },
        async getPeriods(): Promise<void> {
            await axios.get(`/v1/orders/${this.id}/periods`)
            .then((response: AxiosResponse) => {
                for (let i = 0; i < response.data.length; i++) {
                    this.periods.push({...response.data[i], number: i+1})
                    
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        },
        /**
         * Request to update the order_worker
         * @param body The body of the request
         */
        requestToUpdateOrderWorker(body: {[key: string]: {
            status: number,
            periods: number[],
            [key: string]: RequestBodyValue
        }})
        {
            axios.put(`/v1/orders/${this.id}/workers`, body)
            .then(() => {
                EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.worker_successfully_updated') });
                
                // Mise à jour locale des données
                const workerId = Object.keys(body)[0]
                const updateData = body[workerId]
                const worker = this.workers.find(w => w.id === Number(workerId))
                
                if (worker) {
                    worker.order_workers.forEach(ow => {
                        if (updateData.periods.includes(ow.period_id)) {
                            Object.keys(updateData).forEach(key => {
                                if (key !== 'periods' && key !== 'status') {
                                    const value = updateData[key]
                                    if (typeof value !== 'object' || !Array.isArray(value)) {
                                        ow[key] = value as OrderWorkerValue
                                    }
                                }
                            })
                        }
                    })
                }

                if (this.fromContract) {
                    this.$emit('contractDone')
                }
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
        },
        /**
         * Update the status of the order_worker
         * @param workerId The id of the worker
         * @param periodId The id of the period
         * @param statusId The id of the status
         * @param action The action to perform
         * @param currentValue The current value of the data to change
         */
        updateOrderWorkerStatus(workerId: number, periodId: number, statusId: number, action: string, currentValue: UpdateValueType, startedAt: string|null = null)
        {
            let bodyValue = {} as {[key: string]: null|string|boolean}
            if (action === "arrived_at" && !currentValue) {
                bodyValue[action] = startedAt
            } else if (action === "arrived_at" && currentValue) {
                bodyValue[action] = null
            }
            if (action === "contract_done") {
                bodyValue[action] = !currentValue
            }
            this.requestToUpdateOrderWorker({
                [workerId]: {
                    "status": statusId,
                    "periods": [periodId],
                    [action]: bodyValue[action] as null|boolean|string
                }
            })
        }
    }
})
