
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import { state } from "@/plugins/state"

interface Space {
  id: number
  name: string
}

interface User {
  firstname: string
  lastname: string
  id: number,
  email: string
}

export default Vue.extend({
    name: "UsersAutocompleteMixin",
    data: () => ({
        users: [] as User[],
        selectedUser: null as number|null,
        spaces: "" as string,
        userReference: null as User|null,
        disabled: false as boolean,
        saveUser: null as User|null
    }),
    computed: {
      stateAgency: {
        get() {
          return state.agency
        },
        cache: false  // Need to force the reactivity
      }
    },
    watch: {
      selectedUser: {
        async handler() {
          await this.getUsers()
        },
        immediate: true
      },
      async disabled(val: boolean) {
        this.disabled = val
        if (!this.disabled) {
          await this.setSpaces()
          await this.getUsers()
        }
      },
      stateAgency: {
        async handler() {
          if (!this.disabled) {
            await this.setSpaces()
            await this.getUsers()
          }
        },
        deep: true,  // Need to detect the deep changes
        immediate: true
      }
    },
    async mounted() {
      await this.setSpaces()
      await this.getUsers()
    },
    methods: {
      /**
       * Set the spaces for the connected user
       */
      async setSpaces() {
        if (state.agency === null) {
          this.spaces = (await this.getSpacesForThisConnectedUser()).join(',')
        } else {
          this.spaces = state.agency
        }
      },
      /**
       * Get the users for the connected user
       * @param {string|null} search The search string
       */
      async getUsers(search: string|null = null) {
        this.users = []
        if (this.spaces.length > 0) {
          let url = `/v1/space/users?selectAll=false&spaces=${this.spaces}`
          if (search) {
            url += `&lastname=${search}&firstname=${search}`
          }
          this.users = await axios
            .get(url)
            .then((response: AxiosResponse) => {
              return response.data.map((user: User) => {
                if(!(user.email.includes('dispojob'))) {
                  return user
                }
              }).filter((user: User) => user !== undefined)
            })
            .catch((error: AxiosError) => {
              EventBus.$emit('snackbar',{axiosError: error.response})
              return []
            })
          if (this.selectedUser != null && !this.users.find((user: User) => user.id === this.selectedUser)) {
            await this.getSpecificUser()
          }
        }
      },
      /**
       * Get the connected user
       */
      async getSpecificUser() {
        await axios
          .get(`/v1/space/users/${this.selectedUser}`)
          .then((userResponse: AxiosResponse) => {
            this.users.push(userResponse.data)
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error.response})
          })
      },
      /**
       * Get the spaces for the connected user
       * @returns {Promise<number[]>} The spaces for the connected user
       */
      async getSpacesForThisConnectedUser(): Promise<number[]> {
        return await axios
          .get(`/v1/spaces?deleted=false`)
          .then((spacesResponse: AxiosResponse) => {
            return spacesResponse.data.map((space: Space) => space.id)
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error.response})
          })
      }
    }
})
