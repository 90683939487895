
import Vue from "vue"
import { state } from "@/plugins/state"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

export default Vue.extend({
  name: "GenericAutocomplete",
  props: {
    value: {
      type: Array,
      default:() => []   
    },
    dataAutocomplete: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chip: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    addItems: {
      type: Array,
      default:() => []   
    }
  },
  data() {
    return {
      localValue: this.value,
      items: [] as string[],
      search: "" as string,
      spaces: null as string | null
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.localValue = newVal
      },
      deep: true
    },
    localValue: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    async spaces() {
        await this.getData()
    },
    async search(search: string) {
        await this.getData(search)
    },
    addItems: {
      handler() {
        this.getData()
      },
      deep: true
    },
    stateAgency: {
      async handler() {
        this.spaces = state.agency
      },
      deep: true,  // Need to detect the deep changes
      immediate: true
    }
  },
  computed: {
    stateAgency: {
      get() {
        return state.agency
      },
      cache: false  // Need to force the reactivity
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
   /**
       * Get tasks from orders with same position
       */
       async getData(search: string|null = null) {
        let spaceIdsUrl = ""
        if (this.spaces) {
          spaceIdsUrl = "&space_id=" + this.spaces
        }
        let searchUrl = ""
        if (search) {
          searchUrl = "&search=" + search
        }
        let url = `/v1/autocomplete?data=${this.dataAutocomplete}${spaceIdsUrl}${searchUrl}`
        this.items = await axios
          .get(url)
          .then((response: AxiosResponse) => {
            if (this.addItems && this.addItems.length > 0) {
              return [...new Set([...this.addItems, ...response.data])]
            }
            return response.data
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error.response})
            return []
          })
      }
  }
})
