
import Vue, { PropType, VueConstructor } from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import ContactsAutocompleteMixin from "@/mixins/ContactsAutocompleteMixin.vue"

interface BaseContact {
  first_name: string
  last_name: string
  email: string|null
  client_id: number
}

interface Contact extends BaseContact {
  phone: string|null
  id: number|null
  contact_phone: string|null
  contact_email: string|null
}

interface ContactBody extends BaseContact {
  mobile_phone_number: string|null
}

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof ContactsAutocompleteMixin>>).extend({
  name: "Contact",
  mixins: [
    ContactsAutocompleteMixin
  ],
  components: {
  },
  props: {
    orderContact: {
      type: Object as PropType<Contact>,
      required: true
    },
    position: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formIsValid: false,
    selectedContact: {id:null, first_name: '', last_name: ''} as Contact,
    method: 'post' as 'post'|'put',
    modalTitle: '',
    dialog: false,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    disableInput: false,
    showDeleteContact: false,
    inputContact: null as Contact | null,
    refContact : {id:null, first_name: '', last_name: ''} as Contact
  }),
  computed: {
    emailRule() {
      return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
    },
    phoneNumberRule() {
      return (v: string) => (!v || (v.length === 10)) || this.$vuetify.lang.t('$vuetify.invalid_mobile_phone_number')
    },
    noNumberRule() {
      return (v: string) => {
        if (!v) return true
        return !/\d/.test(v) || this.$vuetify.lang.t('$vuetify.no_numbers_allowed')
      }
    },
    hasRequiredDataContact() {
      return !!this.selectedContact.email || !!this.selectedContact.phone
    },
    getContactDisplayName() {
      return (contact: Contact | null) => {
        if (!contact) return ''
        if (!contact.first_name && !contact.last_name) return ''
        if (!contact.first_name && contact.last_name) return contact.last_name.toUpperCase()
        if (contact.first_name && !contact.last_name) return contact.first_name
        return `${contact.first_name} ${contact.last_name.toUpperCase()}`
      }
    }
  },
  watch: {
    inputContact() {
      this.selectedContact = this.inputContact as Contact;
    },
    disabled() {
      this.showDeleteContact = this.showingDeleteContact()
    },
    selectedContact(newVal: Contact|null) {
      if (newVal === null) {
        this.selectedContact = this.refContact
      }
      this.$emit('contactSelected', this.selectedContact?.id)
      this.showDeleteContact = this.showingDeleteContact()
    },
    position: {
      handler(newVal: number|null) {
        if (newVal === null) {
          this.selectedContact = this.refContact
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.orderContact.contact_phone !== null || this.orderContact.contact_email !== null) {
      this.inputContact = this.$props.orderContact
    } else {
      this.inputContact = {first_name: '', last_name: ''} as Contact
    }
  },
  methods: {
    /**
     * Close the modal
     */
    closeModal() {
      this.inputContact = this.$props.orderContact
      this.dialog = false
    },
    /**
     * Check if the delete contact button should be shown
     * @returns boolean
     */
    showingDeleteContact(): boolean {
      return (!this.disabled && this.inputContact !== null && (this.inputContact?.email != null || this.inputContact?.phone != null))
    },
    /**
     * Open the modal to create or edit a contact
     * @param method 'post'|'put'
     * @param title title of the modal
     */
    openModal(method: 'post'|'put', title:string) {
      this.method = method
      this.modalTitle = this.$vuetify.lang.t(`$vuetify.${title}`)
      this.dialog = true
    },
    /**
     * Submit the contact form
     */
    submitContact() {
      let body: ContactBody | ContactBody[] = {
        first_name: this.selectedContact.first_name,
        last_name: this.selectedContact.last_name,
        email: this.selectedContact.email,
        client_id: this.$props.position,
        mobile_phone_number: this.selectedContact.phone
      }
      let url = ''
      if (this.method === 'post') {
        url = '/v1/contacts'
        body = [body]
      } else {
        url = `/v1/contacts/${this.selectedContact.id}`
      }
      axios[this.method](url, body)
        .then((response: AxiosResponse) => {
          let action = this.method === 'post' ? 'created' : 'updated'
          let message = "contact_successfully_" + action
          let color = 'success'
          if (Array.isArray(response.data) &&  typeof response.data[0] === "string") {
            message = "invalid_data"
            color = 'error'
            this.dialog = false
          } else {
            if (Array.isArray(response.data)) {
              this.selectedContact.id = response.data[0][0] ?? this.selectedContact.id
            }
            this.$emit('contactSelected', this.selectedContact?.id)
          }
          (this.$refs.autocomplete as any).blur();
          this.inputContact = this.selectedContact
          this.getContacts()
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t(`$vuetify.${message}`), color: color})
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    }
  }
})
